import { Component, Input } from '@angular/core';

import { SettingsServices } from 'src/app/services/commons/settings.services';

import { Item } from 'src/app/models/item';

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})
export class ItemComponent {

  @Input() item: Item = null; 

  constructor(
    public settingsServices: SettingsServices
  ) { 
  }
}
