import { Component } from '@angular/core';

import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { DataAccessServices } from 'src/app/services/commons/data.access.services';

@Component({
  selector: 'app-sort',
  templateUrl: './sort.component.html',
  styleUrls: ['./sort.component.scss']
})
export class SortComponent {

  public constructor(
    private bottomSheetRef: MatBottomSheetRef<SortComponent>,
    public dataAccessServices: DataAccessServices
  ) {}

  openLink(event: MouseEvent): void {
    this.bottomSheetRef.dismiss();
    event.preventDefault();
  }
}
