import { Component, EventEmitter, Output } from '@angular/core';

import { PageEvent } from '@angular/material/paginator';
import { List } from 'linqts';

import { DataAccessServices } from 'src/app/services/commons/data.access.services';

import { Filters } from 'src/app/models/filters';
import { Item } from 'src/app/models/item';
import { SupplierTotal } from 'src/app/models/supplier.total';
import { ItemsSearchResult } from 'src/app/models/items.search.result';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {

  public isSearching: boolean = false;
  public supplierTotals: Array<SupplierTotal> = new Array<SupplierTotal>();
  public itemsSearchResults: Array<ItemsSearchResult> = new Array<ItemsSearchResult>();

  constructor(
    public dataAccessServices: DataAccessServices
  ) {}

  public setSearchResult(data: Array<ItemsSearchResult>): void {
    console.log('DashboardComponent.setSearchResult: Set items', data);
    this.dataAccessServices.itemsTotal = 0;
    this.dataAccessServices.items = new Array<Item>();
    data.forEach(stack => {
      this.dataAccessServices.items = this.dataAccessServices.items.concat(stack.items);
      this.dataAccessServices.itemsTotal += stack.total;
      const firstItem = stack.items[0];
      if(firstItem !== undefined && firstItem !== null) {
        firstItem.supplier.totalItems = stack.total;
      }
    });

    this.dataAccessServices.suppliers.forEach(supplier => {
      this.supplierTotals[supplier.name] = new List(this.dataAccessServices.items).Where(i => i.supplier.nickname === supplier.name).Count();
    });

    this.dataAccessServices.allItems = this.dataAccessServices.items.slice();
    this.toggleFilters(this.dataAccessServices.filters);
  }

  public toggleFilters(data: Filters): void {
    console.log('DashboardComponent.toggleFilters: Filters', data, this.dataAccessServices.items);
    
    let temp = Array<Item>();

    data.selectedSuppliers.forEach(selectedSupplier => {
      temp = temp.concat(new List(this.dataAccessServices.allItems).Where(i => i.supplier._id === selectedSupplier._id).ToArray());
    });

    console.log('DashboardComponent.toggleFilters: After suppliers filter', temp);

    temp = new List(temp).Where(i => (parseFloat(i.price) >= data.selectedMinPrice && parseFloat(i.price) <= data.selectedMaxPrice) || i.price === null).ToArray();
    
    // sort
    if(this.dataAccessServices.filters.upperPrice === true) {
      temp = new List(temp).OrderBy(item => item.price).ToArray();
    } else {
      temp = new List(temp).OrderByDescending(item => item.price).ToArray();
    }
    
    if(this.dataAccessServices.filters.upperName === true) {
      temp = new List(temp).OrderBy(item => item.name).ToArray();
    } else {
      temp = new List(temp).OrderByDescending(item => item.name).ToArray();
    }

    this.dataAccessServices.items = temp;
  }

  public toggleIsSearching(data: boolean): void {
    this.isSearching = data;
  }

  public async handlePageEvent(event: PageEvent): Promise<void> {
    console.log('DashboardComponent.handlePageEvent : ', event);
    this.dataAccessServices.itemsPageIndex = event.pageIndex === 0 ? 1 : event.pageIndex;
    this.dataAccessServices.itemsPageSize = event.pageSize;
    this.dataAccessServices.triggerPageEvent();
  }
}
