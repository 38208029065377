import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from 'src/environments/environment';

import * as CryptoJS from 'crypto-js';

import { RealmTokenServices } from '../commons/realm.token.services';

import { User } from './../../models/user';

@Injectable({
  providedIn: 'root'
})
export class UsersServices {

  constructor(
    private httpClient: HttpClient,
    private realmTokenServices: RealmTokenServices
  ) { }

    public async authenticate(email: string, password: string): Promise<User> {
      console.log('UsersServices.authenticate: Start to get user by email', email);
      
      return new Promise<User>(async (resolve, reject) => {

        let token = null;
        await this.realmTokenServices.getToken().then(data => {
          console.log('UsersServices.authenticate : Token', data);
          token = data;
        });

        const findUrl = `${environment.mongoapi.apiFindOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };

        const hash = CryptoJS.SHA256(`${User.prefix_salt}${password}${User.suffix_salt}`);
        password = hash.toString(CryptoJS.enc.Hex);
        
        await this.httpClient.post<User>(findUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `${environment.database.prefix}users`, filter: { 'email': { $eq: email }, 'password': { $eq: password } } }, httpOptions).toPromise().then((data: any) => {
          console.log('UsersServices.authenticate : Items', data);
          resolve(data.document);
        });
      });
    }
    
}
