import { Component, EventEmitter, Output } from '@angular/core';

import { List } from 'linqts';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

import { SettingsServices } from 'src/app/services/commons/settings.services';
import { DataAccessServices } from 'src/app/services/commons/data.access.services';
import { SortComponent } from './sort/sort.component';

import { Filters } from 'src/app/models/filters';
import { Supplier } from 'src/app/models/supplier.model';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent {

  public panelOpenState: boolean = false;

  @Output() filtersChanged = new EventEmitter<Filters>();

  public constructor(
    public settingsServices: SettingsServices,
    private bottomSheet: MatBottomSheet,
    public dataAccessServices: DataAccessServices
  ) {
    this.dataAccessServices.filters.minPrice = 1;
    this.dataAccessServices.filters.maxPrice = 100000;
    this.dataAccessServices.filters.selectedMinPrice = 1;
    this.dataAccessServices.filters.selectedMaxPrice = 100000;
    this.dataAccessServices.filters.selectedSuppliers = this.dataAccessServices.suppliers.slice();
    this.dataAccessServices.filters.upperPrice = true;
    this.dataAccessServices.filters.upperName = true;
  }

  public toggleSupplier(toggle: boolean, supplier: Supplier) {
    console.log("FiltersComponent.toggleSupplier : Toggle supplier", supplier);

    if(toggle === true) {
      this.addSupplier(supplier);
    } else {
      this.removeSupplier(supplier);
    }

    this.filtersChanged.emit(this.dataAccessServices.filters);
  }

  public isSelected(supplier: Supplier): boolean {
    return new List(this.dataAccessServices.filters.selectedSuppliers).Any(ss => ss._id === supplier._id);
  }

  public formatLabel(value: number): string {
    if (value >= 1000) {
      return Math.round(value / 1000) + 'k';
    }

    return `${value}`;
  }

  public priceRangeChanged(): void {
    this.filtersChanged.emit(this.dataAccessServices.filters);
  }

  public openSortPanel(): void {
    this.bottomSheet.open(SortComponent);
  }

  private addSupplier(supplier: Supplier)
  {
    const supplierData = new List(this.dataAccessServices.suppliers).First(s => s._id == supplier._id);
    this.dataAccessServices.filters.selectedSuppliers.push(supplierData);
  }

  private removeSupplier(supplier: Supplier)
  {
    this.dataAccessServices.filters.selectedSuppliers.forEach((element, index) => {
      if(element._id === supplier._id) {
        this.dataAccessServices.filters.selectedSuppliers.splice(index, 1);
      }
    });
  }
}
