<div class="row">
  <div class="col-10">
    <mat-expansion-panel (opened)="panelOpenState = true"
                       (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'filters.filters' | translate }} <span *ngIf="dataAccessServices.itemsTotal !== undefined && dataAccessServices.itemsTotal !== null">-</span> <b *ngIf="dataAccessServices.itemsTotal !== undefined && dataAccessServices.itemsTotal !== null">{{ dataAccessServices.itemsTotal }} Result(s)</b>
        </mat-panel-title>
      </mat-expansion-panel-header>
      
      <div class="row">
          <div class="col-md-4">
            <div class="row">
              <mat-label>{{ 'filters.suppliers' | translate }}</mat-label>
            </div>
            <div class="row">
              <mat-checkbox 
                *ngFor="let supplier of dataAccessServices.suppliers" 
                [color]="'primary'" 
                [checked]="isSelected(supplier)" 
                (change)="toggleSupplier($event.checked, supplier)">
                {{ supplier.name }} <i *ngIf="isSelected(supplier)">({{ supplier.totalItems }}<span *ngIf="supplier.totalItems >= 1000">+</span>)</i>
              </mat-checkbox>
            </div>
          </div>
          <div class="col-md-4">
            <div class="row">
              <div class="col-md-2">
                <mat-label>{{ 'filters.minprice' | translate }}</mat-label>
              </div>
              <div class="col-md-4">
                <mat-form-field class="full-width">
                  <input type="number" matInput [(ngModel)]="dataAccessServices.filters.selectedMinPrice" (ngModelChange)="priceRangeChanged()">
                </mat-form-field>
              </div>
              <div class="col-md-2">
                <mat-label>{{ 'filters.maxprice' | translate }}</mat-label>
              </div>
              <div class="col-md-4">
                <mat-form-field class="full-width">
                  <input type="number" matInput [(ngModel)]="dataAccessServices.filters.selectedMaxPrice" (ngModelChange)="priceRangeChanged()">
                </mat-form-field>
              </div>
            </div>  
          </div>
          
      </div>

    </mat-expansion-panel>
  </div>
  <div class="col-2">
    <button mat-fab extended color="primary" (click)="openSortPanel()">
      <mat-icon [ngStyle]="{ 'margin-right': settingsServices.isCellPhone ? '-10px' : '12px' }">sort</mat-icon>
      <span *ngIf="settingsServices.isCellPhone === false">{{ 'filters.sort' | translate }}</span>
    </button>
  </div>
</div>


  