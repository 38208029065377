import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { List } from 'linqts';

import { DataAccessServices } from 'src/app/services/commons/data.access.services';
import { SuppliersServices } from 'src/app/services/suppliers/suppliers.services';

import { Supplier } from 'src/app/models/supplier.model';
import { DesignMainCategory } from 'src/app/models/design.main.category';
import { SupplierState } from 'src/app/models/supplier.state.enum';
import { IngestSupplierState } from 'src/app/models/ingest.supplier.state.enum';

@Component({
  selector: 'app-supplier',
  templateUrl: './supplier.component.html',
  styleUrls: ['./supplier.component.scss']
})
export class SupplierComponent {

  public supplier: Supplier = null;
  public supplierStatesArray: any = null;
  public ingestSupplierStatesArray: any = null;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dataAccessServices: DataAccessServices,
    private suppliersServices: SuppliersServices) {

    this.activatedRoute.params.subscribe(params => {
      console.log('SupplierComponent.constructor : Retrieved params', params);
      const supplierId = params['supplierid'];
      if(this.dataAccessServices.allSuppliers === null || this.dataAccessServices.allSuppliers === undefined || this.dataAccessServices.allSuppliers.length === 0) {
        this.router.navigate(['/administration']);
      }

      this.supplier = new List(this.dataAccessServices.allSuppliers).First(i => i._id == supplierId);
      if(this.supplier === null || this.supplier === undefined) {
        this.router.navigate(['/administration']);
      }

      this.supplierStatesArray = Object.keys(SupplierState)
      .filter(key => !isNaN(Number(SupplierState[key])))
      .map(key => ({ value: SupplierState[key], viewValue: key }));

      this.ingestSupplierStatesArray = Object.keys(IngestSupplierState)
      .filter(key => !isNaN(Number(IngestSupplierState[key])))
      .map(key => ({ value: IngestSupplierState[key], viewValue: key }));

      console.log('SupplierComponent.constructor : Selected supplier', this.supplier);
    });
  }

  public onSupplierChange(event: any) {
    console.log('SupplierComponent.onSupplierChange : Supplier changed', event, this.supplier);
    this.suppliersServices.updateSupplier(this.supplier).then((data: Supplier) => {
      console.log('SupplierComponent.onSupplierChange : Supplier updated', data);
    });
  }
}
