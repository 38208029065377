import { Supplier } from "./supplier.model";

export class Filters {
    selectedSuppliers: Array<Supplier>;
    minPrice: number;
    maxPrice: number;
    selectedMinPrice: number;
    selectedMaxPrice: number;

    upperPrice: boolean;
    upperName: boolean;
}
