<div class="row">
    <div class="col-md-12">
        <mat-card>
            <mat-card-header>
                <mat-card-title>{{ 'itemdetails.pictures' | translate }}</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="row" *ngIf="selectedItem.pictures === null || selectedItem.pictures === undefined || selectedItem.pictures.length === 0">
                    <img mat-card-image src="./../../../assets/images/empty-picture.jpg" alt="No picture">
                </div>
                <div class="row" *ngIf="selectedItem.pictures !== null && selectedItem.pictures !== undefined && selectedItem.pictures.length > 0"> 
                    <div class="col-md-4" *ngFor="let picture of selectedItem.pictures" style="padding:10px;">
                        <img mat-card-image src="{{ picture.url }}" alt="Item picture" [ngStyle]="{'max-width': settingsServices.isCellPhone ? '60vw' : '25vw'}">
                    </div>
                </div>
            </mat-card-content>
            <mat-card-actions align="end">
            </mat-card-actions>
        </mat-card>
    </div>
</div>
<div class="row mt-3">
    <div class="col-md-12">
        <mat-card>
            <mat-card-header>
                <mat-card-title>{{ 'itemdetails.description' | translate }}</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <mat-label>{{ selectedItem.description }}</mat-label>
            </mat-card-content>
            <mat-card-actions align="end">
                <div class="row">
                    <div class="col-6">
                        <button mat-fab extended color="primary" (click)="openSupplierLink()">
                            {{ 'itemdetails.supplierlink' | translate }}
                        </button>
                    </div>
                    <div class="col-6">
                        <button mat-fab extended color="primary" [routerLink]="['/search']">
                            {{ 'generic.return' | translate }}
                        </button>
                    </div>
                </div>
            </mat-card-actions>
        </mat-card>
    </div>
</div>
<div class="row mt-3">
    <div class="col-md-12">
        <mat-card>
            <mat-card-header>
                <mat-card-title>{{ 'itemdetails.location' | translate }}</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div id="map" style="width:100%; height:500px;"></div>
            </mat-card-content>
            <mat-card-actions align="end">
                
            </mat-card-actions>
        </mat-card>
    </div>
</div>