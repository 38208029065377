import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from 'src/environments/environment';

import { RealmTokenServices } from '../commons/realm.token.services';

import { Item } from 'src/app/models/item';
import { ItemPicture } from 'src/app/models/item.picture';
import { ItemsSearchResult } from 'src/app/models/items.search.result';
import { Supplier } from 'src/app/models/supplier.model';

@Injectable({
  providedIn: 'root'
})
export class ItemsServices {

  constructor(
    private httpClient: HttpClient,
    private realmTokenServices: RealmTokenServices) { }

    public async searchItems(supplier: Supplier, keys: Array<string>, pageSize: number, startIndex: number): Promise<ItemsSearchResult> {
      console.log('ItemsServices.searchItems: Start to search items', supplier, keys, pageSize, startIndex);
      return new Promise<ItemsSearchResult>(async (resolve, reject) => {

        let token = null;
        await this.realmTokenServices.getToken().then(data => {
          console.log('ItemsServices.searchItems : Token', data);
          token = data;
        });

        const findUrl = `${environment.mongoapi.apiFindUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };

        let itemsSearchResult = new ItemsSearchResult();

        let query = {
          $or: []
        };

        keys.forEach(key => {
          query.$or.push({ 'name': { $regex: key, $options: 'i'} });
          query.$or.push({ 'description': { $regex: key, $options: 'i'} });
        });
        
        await this.httpClient.post<Item[]>(findUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `${environment.database.prefix}${supplier.nickname}_items`, filter: query }, httpOptions).toPromise().then((data: any) => {
          console.log('ItemsServices.searchItems : Total items', data);
          itemsSearchResult.total = data.documents.length;
        });

        await this.httpClient.post<Item[]>(findUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `${environment.database.prefix}${supplier.nickname}_items`, limit: pageSize, skip: (startIndex - 1) * pageSize, filter: query }, httpOptions).toPromise().then((data: any) => {
          console.log('ItemsServices.searchItems : Items', data);
          itemsSearchResult.items = data.documents;
        });

        itemsSearchResult.items.forEach(item => { item.supplier = supplier });

        resolve(itemsSearchResult);
      });
    }  

    public async getItemPictures(item: Item): Promise<Array<ItemPicture>> {
      console.log('ItemsServices.getItemPictures: Start to get item pictures', item);
      return new Promise<Array<ItemPicture>>(async (resolve, reject) => {

        let token = null;
        await this.realmTokenServices.getToken().then(data => {
          console.log('ItemsServices.getItemPictures : Token', data);
          token = data;
        });

        const findUrl = `${environment.mongoapi.apiFindUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        
        await this.httpClient.post<ItemPicture[]>(findUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `${environment.database.prefix}${item.supplier.nickname}_itempictures`, limit: 100, filter: { 'itemid': { $eq: { $oid: item._id } } } }, httpOptions).toPromise().then((data: any) => {
          console.log('ItemsServices.getItemPictures : Item pictures', data);
          resolve(data.documents);
        });
      
      });
    }

    public async updateItem(item: Item): Promise<Item> {
      console.log('ItemsServices.updateItem: Start to update item', item);
      return new Promise<Item>(async (resolve, reject) => {

        let token = null;
        await this.realmTokenServices.getToken().then(data => {
          console.log('ItemsServices.updateItem : Token', data);
          token = data;
        });

        const updateUrl = `${environment.mongoapi.apiUpdateOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        
        await this.httpClient.post<Item>(updateUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `${environment.database.prefix}${item.supplier.nickname}_items`, filter: { '_id': { $eq: { $oid: item._id } } }, update: { $set: { 'score': item.score } } }, httpOptions).toPromise().then((data: any) => {
          console.log('ItemsServices.updateItem : Item updated', data);
          resolve(data.document);
        });

      });
    }

}
