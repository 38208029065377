import { Component, EventEmitter, Output } from '@angular/core';
import { Subscription } from 'rxjs';

import { SettingsServices } from 'src/app/services/commons/settings.services';
import { DataAccessServices } from 'src/app/services/commons/data.access.services';
import { ItemsServices } from 'src/app/services/items/items.services';

import { ItemsSearchResult } from 'src/app/models/items.search.result';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent {

  public searchText: string = null;
  public isSearching: boolean = false;

  @Output() searchResultEvent = new EventEmitter<Array<ItemsSearchResult>>();
  @Output() isSearchingEvent = new EventEmitter<boolean>();

  private pageEventSubscription: Subscription;

  public constructor(
    public settingsServices: SettingsServices,
    private dataAccessServices: DataAccessServices,
    private itemsServices: ItemsServices
  ) {
    this.pageEventSubscription = this.dataAccessServices.pageEvent$.subscribe(() => {
      console.log('SearchComponent:constructor : pageEventSubscription');
      this.searchItems();
    });
  }

  ngOnDestroy() {
    this.pageEventSubscription.unsubscribe();
  }

  public async searchItems(): Promise<void> {

    if(this.isSearching == false) {
      this.isSearching = true;
      this.isSearchingEvent.emit(this.isSearching);

      let result = new Array<ItemsSearchResult>();

      for(let i = 0; i < this.dataAccessServices.filters.selectedSuppliers.length; i++) {
        const supplier = this.dataAccessServices.filters.selectedSuppliers[i];
        console.log('SearchComponent.searchItems: Search items for', supplier);

        const keys = this.searchText.split(' ');
        keys.forEach(k => k.split(','));
        keys.forEach(k => k.split(';'));
        keys.forEach(k => k.split('+'));
        keys.forEach(k => k.split('.'));
        keys.forEach(k => k.split('|'));
        
        await this.itemsServices.searchItems(supplier, keys, Math.round(this.dataAccessServices.itemsPageSize / this.dataAccessServices.suppliers.length), this.dataAccessServices.itemsPageIndex).then((data: ItemsSearchResult) => {
          console.log('SearchComponent.searchItems: Search items result', data);
          result.push(data);
        });
      }

      this.searchResultEvent.emit(result);
      this.isSearching = false;
      this.isSearchingEvent.emit(this.isSearching);
    }
  }
}
