import { Component } from '@angular/core';

import { List } from 'linqts';

import { environment } from 'src/environments/environment';

import { DataAccessServices } from 'src/app/services/commons/data.access.services';
import { PaymentsServices } from 'src/app/services/payments/payments.services';

import { Payment } from 'src/app/models/payment.model';
import { LicenceType } from 'src/app/models/licence.type.enum';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {

  public expirationDate: Date = new Date();
  public payments: Array<Payment> = new Array<Payment>();

  constructor(
    public dataAccessServices: DataAccessServices,
    private paymentsServices: PaymentsServices,
  ) {}

  async ngOnInit(): Promise<void> {
    this.getPayments();
  };

  public getPrice(payment: Payment): number {
    return payment.amount / 100;
  }

  public downloadBill(payment: Payment): void {
    
  }

  private async getPayments(): Promise<void> {
    await this.paymentsServices.getByUser(this.dataAccessServices.currentUser).then((data : Array<Payment>) => {
        console.log('ProfileComponent.getPayments : Retrieved bills', data);
        this.payments = data;
        const lastPayment = new List(data).OrderBy(p => p.creation).Last();
        const date = new Date(lastPayment.creation);
        const today = new Date();
        if(lastPayment.amount === LicenceType.PARTICULAR_MONTHLY) {
          const computedExpirationDate = new Date(date);
          computedExpirationDate.setDate(computedExpirationDate.getDate() + 30);
          this.expirationDate = computedExpirationDate;
        }
    });
  }
}
