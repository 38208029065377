import { Component, ElementRef, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from 'src/environments/environment';

import { MatSnackBar } from '@angular/material/snack-bar';
import { List } from 'linqts';
import { TranslateService } from '@ngx-translate/core';

import { SettingsServices } from 'src/app/services/commons/settings.services';
import { DataAccessServices } from 'src/app/services/commons/data.access.services';
import { LocalStorageServices } from 'src/app/services/commons/local.storage.services';
import { UsersServices } from 'src/app/services/users/users.services';
import { PaymentsServices } from 'src/app/services/payments/payments.services';

import { User } from 'src/app/models/user';
import { Payment } from 'src/app/models/payment.model';
import { LicenceType } from 'src/app/models/licence.type.enum';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  public isAuthenticating: boolean = false;
  public email: string = null;
  public password: string = null;
  public hidePassword: boolean = true;
  public environment: any = environment;

  constructor(
    private router: Router,
    public settingsServices: SettingsServices,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private localStorageServices: LocalStorageServices,
    private dataAccessServices: DataAccessServices,
    private usersServices: UsersServices,
    private paymentsServices: PaymentsServices
  ) { }

  public async authenticate(): Promise<void> {
    this.email = this.email.toLowerCase();
    await this.usersServices.authenticate(this.email, this.password).then((user: User) => {
      this.dataAccessServices.currentUser = user;
      user.password = '**********';
      this.localStorageServices.set('currentuser', user);
    }).catch(error => {
      console.error('LoginComponent.authenticate : Failed', error);
      if(error === 404) {
        this.snackBar.open(this.translateService.instant('login.usernotfound'));
      }
      else if(error === 500) {
        this.snackBar.open(this.translateService.instant('generic.internalerror'));
      }
    });

    await this.paymentsServices.getByUser(this.dataAccessServices.currentUser).then((data: Array<Payment>) => {
      console.log('LoginComponent.authenticate : Get payments by user', data);
      const lastPayment = new List(data).OrderBy(p => p.creation).Last();
      const date = new Date(lastPayment.creation);
      const today = new Date();

      if(lastPayment.amount === LicenceType.PARTICULAR_MONTHLY) {
        const thirtyDaysAgo = new Date(today);
        thirtyDaysAgo.setDate(today.getDate() - 30);
        if (date > thirtyDaysAgo) {
          console.log("LoginComponent.authenticate : 30 days licence valide");
          this.router.navigate(['/search']);
        } else {
          this.snackBar.open(this.translateService.instant('login.licenceexpired'));
          window.open(environment.globalVestaUrl + '/userinformations/20', '_blank');
        }
      } else {
        this.snackBar.open(this.translateService.instant('login.unmanagedlicencepleasecontacttheadministrator'));
      }
    });
  }

  public redirectToGlobalVestaCommercial(): void {
    window.open(`${environment.globalVestaUrl}/userinformations/1999`, '_blank');
  }

  public redirectToGlobalVesta(): void {
    window.open(`${environment.globalVestaUrl}`, '_blank');
  }
}
