<table mat-table [dataSource]="dataAccessServices.allSuppliers" class="mat-elevation-z8">

    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let element"> {{ element.name }} </td>
    </ng-container>
  
    <ng-container matColumnDef="nickname">
        <th mat-header-cell *matHeaderCellDef> Nickname </th>
        <td mat-cell *matCellDef="let element"> {{ element.nickname }} </td>
    </ng-container>
  
    <ng-container matColumnDef="url">
        <th mat-header-cell *matHeaderCellDef> Url </th>
        <td mat-cell *matCellDef="let element"> {{ element.url }} </td>
    </ng-container>
  
    <ng-container matColumnDef="logo">
        <th mat-header-cell *matHeaderCellDef> Logo </th>
        <td mat-cell *matCellDef="let element"> {{element.logo}} </td>
    </ng-container>

    <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element"> <button mat-flat-button color="primary" [routerLink]="['/supplier', element._id]"> {{ 'generic.edit' | translate }}</button> </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  
  