import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from 'src/app/services/commons/auth.guard.services';

import { ItemDetailsComponent } from './item-details.component';

const routes: Routes = [
  { path: 'details/:itemid', component: ItemDetailsComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class ItemDetailsRoutingModule { }
