import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../../services/commons/auth.guard.services';

import { SupplierComponent } from './supplier.component';

const routes: Routes = [
  { path: 'supplier/:supplierid', component: SupplierComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class SupplierRoutingModule { }
