import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from 'src/environments/environment';

import { LocalStorageServices } from './services/commons/local.storage.services';
import { DataAccessServices } from './services/commons/data.access.services';
import { SettingsServices } from './services/commons/settings.services';
import { UserProfile } from './models/user.profile.enum';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public UserProfile = UserProfile;

  constructor(
    private router: Router,
    public settingsServices: SettingsServices,
    private localStorageServices: LocalStorageServices,
    public dataAccessServices: DataAccessServices
  ) { 
  }

  ngOnInit(): void {
    this.settingsServices.initialize();
  }

  public redirectOnCommercialWebSite(): void {
    window.open(environment.globalVestaUrl, '_blank');
  }

  public async logout(): Promise<void>{
    this.localStorageServices.remove('realm_token');

    this.localStorageServices.remove('currentuser');
    this.dataAccessServices.currentUser = null;
    this.router.navigate(['login']);
  }
}
