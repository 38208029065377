import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from 'src/environments/environment';

import { RealmTokenServices } from '../commons/realm.token.services';

import { Payment } from 'src/app/models/payment.model';
import { User } from 'src/app/models/user';

@Injectable({
  providedIn: 'root'
})
export class PaymentsServices {

  constructor(
    private httpClient: HttpClient,
    private realmTokenServices: RealmTokenServices) { }
  
    public async getByUser(user: User): Promise<Array<Payment>> {
      console.log('PaymentsServices.getAll: Get all payments', user);

      return new Promise<Array<Payment>>(async (resolve, reject) => {

        let token = null;
        await this.realmTokenServices.getToken().then(data => {
          console.log('PaymentsServices.getAll : Token', data);
          token = data;
        });

        const findUrl = `${environment.mongoapi.apiFindUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        
        await this.httpClient.post<Array<Payment>>(findUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `${environment.database.prefix}payments`, filter: { 'userId': { $eq: user._id } } }, httpOptions).toPromise().then((data: any) => {
          console.log('PaymentsServices.getAll : Payments', data);
          resolve(data.documents);
        });
      });
    } 

}
