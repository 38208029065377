<div class="row">
    <div class="col-md-8 mt-2">
        <mat-card>
            <mat-card-header>
              <mat-card-title>{{ 'profile.bills' | translate }}</mat-card-title>
              <mat-card-subtitle>{{ 'profile.subscriptionend' | translate }} : {{ expirationDate | date:'yyyy-MM-dd' }}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <mat-list role="list">
                    <mat-list-item role="listitem" *ngFor="let payment of payments" style="margin-top:15px;min-height:75px">
                        <div class="row">
                            <div class="col-md-4 col-sm-12 col-xs-12">{{ payment.creation | date:'yyyy-MM-dd hh:mm:ss' }}</div>
                            <div class="col-md-4 col-sm-12 col-xs-12">{{ payment.description }}</div>
                            <div class="col-md-4 col-sm-12 col-xs-12">{{ getPrice(payment) }} $</div>
                            <!--
                            <div class="col-md-3">
                                <button mat-fab extended color="primary" (click)="downloadBill(payment)">
                                    {{ 'profile.download' | translate }}
                                </button>
                            </div>
                            -->
                        </div>
                    </mat-list-item>
                </mat-list>                  
            </mat-card-content>
            <mat-card-actions align="end">
                <!--
                <button mat-fab extended color="primary" class="mt-3">
                    {{ 'profile.updateyouraccount' | translate }}
                </button>
                -->
            </mat-card-actions>
        </mat-card>
    </div>
    <div class="col-md-4 mt-2">
        <mat-card>
            <mat-card-header>
              <mat-card-title> {{ 'profile.personalinformations' | translate }}</mat-card-title>
              <mat-card-subtitle>{{ 'profile.updateyourinformations' | translate }}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="row mt-3">
                    <div class="col-md-6">{{ dataAccessServices.currentUser.firstname }}</div>
                    <div class="col-md-6">{{ dataAccessServices.currentUser.lastname }}</div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-12">{{ dataAccessServices.currentUser.email }}</div>
                </div>
            </mat-card-content>
            <mat-card-actions align="end">
                <!--
                <button mat-fab extended color="primary">
                    {{ 'profile.update' | translate }}
                </button>
                -->
            </mat-card-actions>
          </mat-card>
    </div>
</div>