import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';



import { DataAccessServices } from './data.access.services';
import { LocalStorageServices } from './local.storage.services';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {


  constructor(
    private dataAccessServices: DataAccessServices,
    private localStorageServices: LocalStorageServices) { }

  public async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
    
    console.debug('AuthGuard.canActivate : ', this.dataAccessServices.currentUser);

    if (this.dataAccessServices.currentUser === undefined || this.dataAccessServices.currentUser === null) {
      this.dataAccessServices.currentUser = this.localStorageServices.get('currentuser');
    }

    if (this.dataAccessServices.iscacherefreshed === false) {
      await this.dataAccessServices.initialize().then(() => {
        console.log('AuthGuard.constructor : Application is initialized');
      });
    }

    return this.dataAccessServices.currentUser !== null && this.dataAccessServices.currentUser !== undefined;
  }
}
