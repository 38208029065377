import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserProfile } from 'src/app/models/user.profile.enum';

import { DataAccessServices } from 'src/app/services/commons/data.access.services';

@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.scss']
})
export class AdministrationComponent {

  public displayedColumns: string[] = ['name', 'nickname', 'url', 'logo', 'edit'];

  constructor(
    private router: Router,
    public dataAccessServices: DataAccessServices
  ) {
    if(this.dataAccessServices.currentUser.profile !== UserProfile.Administrator) {
      this.router.navigate(['/search']);
    }
  }
}
