import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GeocodingServices {

    private apiKey = 'AIzaSyDCTLYgRpwU_wbhG8qKMIcn7Hc_tf_RLeQ';
    private apiUrl = 'https://maps.googleapis.com/maps/api/geocode/json';
    
    constructor(private httpClient: HttpClient) {}

    public async getCoordinatesFromAddress(address: string): Promise<any> {
        const encodedAddress = encodeURIComponent(address);
        const url = `${this.apiUrl}?address=${encodedAddress}&key=${this.apiKey}`;

        console.log(`GeocodingServices.getCoordinatesFromAddress : Start to found with: ${url}`);

        return new Promise<any>(async (resolve, reject) => {
            await this.httpClient.get(url).toPromise().then(data => {
                console.log('GeocodingServices.getCoordinatesFromAddress : Found', data);
                resolve(data);
            });
        });
    }

    public async getCoordinatesFromStoreName(store: string): Promise<any> {
        const encodedStoreName = encodeURIComponent(store);
        const url = `${this.apiUrl}?address=${encodedStoreName}&key=${this.apiKey}`;

        console.log(`GeocodingServices.getCoordinatesFromStoreName : Start to found with: ${url}`);

        return new Promise<any>(async (resolve, reject) => {
            await this.httpClient.get(url).toPromise().then(data => {
                console.log('GeocodingServices.getCoordinatesFromStoreName : Found', data);
                resolve(data);
            });
        });
    }
}
