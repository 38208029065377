<mat-toolbar color="primary" *ngIf="dataAccessServices.currentUser !== null">
  <button mat-icon-button matTooltip="Menu" (click)="sidenav.toggle()">
    <mat-icon>menu</mat-icon>
  </button>
  <span class="toolbar-item-spacer"></span>
  <img src="assets/images/logo_gv.png" alt="logo" [ngStyle]="{ 'width': settingsServices.isCellPhone ? '10%' : '2.5%' }" (click)="redirectOnCommercialWebSite()"/>
  <span class="toolbar-item-spacer"></span>
  <avatar mat-icon-button [matMenuTriggerFor]="userAccountMenu" matTooltip="{{ 'menu.myaccount' | translate }}" [isEditable]="false"
          [size]="'40px'"
          [name]="this.dataAccessServices.currentUser.firstname + ' ' + this.dataAccessServices.currentUser.lastname"
          [imageSource]="null"></avatar>
  <mat-menu #userAccountMenu [overlapTrigger]="false" yPosition="below">
    <button mat-menu-item routerLink="/profile">
      <mat-icon>person</mat-icon><span>{{ 'menu.myaccount' | translate }}</span>
    </button>
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="logout()">
      <mat-icon>exit_to_app</mat-icon> {{ 'menu.logout' | translate }}
    </button>
  </mat-menu>
</mat-toolbar>

<mat-sidenav-container class="app-container">
  <mat-sidenav #sidenav mode="side" [class.mat-elevation-z4]="true" class="app-sidenav">
    <mat-nav-list>
      <mat-list-item [routerLink]="'/search'" style="padding-left: 22px;">
        <mat-icon>search</mat-icon><span>{{ 'menu.search' | translate }}</span>
      </mat-list-item>
      <mat-list-item [routerLink]="'/administration'" style="padding-left: 22px;" *ngIf="this.dataAccessServices.currentUser?.profile === UserProfile.Administrator">
        <mat-icon>settings</mat-icon><span>{{ 'menu.administration' | translate }}</span>
      </mat-list-item>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content style="height: auto" [ngStyle]="{'height': (dataAccessServices.currentUser === null) ? 'auto' : '100%'}">

    <div *ngIf="dataAccessServices.currentUser === null">
      <router-outlet></router-outlet>
    </div>
    <div *ngIf="dataAccessServices.currentUser !== null">
      <div class="app-sidenav-content">
        <div class="container">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>

  </mat-sidenav-content>
</mat-sidenav-container>
