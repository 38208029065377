import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { MaterialModule } from '../../shared/modules/material.module';
import { SharedModule } from '../../shared/shared.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { DashboardRoutingModule } from './dashboard.routing.module';

import { DashboardComponent } from './dashboard.component';

import { SearchComponent } from './../../components/search/search.component';
import { FiltersComponent } from './../../components/filters/filters.component';
import { SortComponent } from './../../components/filters/sort/sort.component';
import { ItemComponent } from './../../components/item/item.component';

@NgModule({
  declarations: [
    DashboardComponent,

    SearchComponent,
    FiltersComponent,
    SortComponent,
    ItemComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: false,
    }),

    MaterialModule,
    SharedModule,

    DashboardRoutingModule,
  ]
})
export class DashboardModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
