<mat-tab-group>
    <mat-tab label="Main informations">
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <mat-form-field class="full-width">
                        <mat-label>{{ 'supplier.name' | translate }}</mat-label>
                        <input matInput [(ngModel)]="supplier.name">
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-form-field class="full-width">
                        <mat-label>{{ 'supplier.nickname' | translate }}</mat-label>
                        <input matInput [(ngModel)]="supplier.nickname">
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-form-field>
                        <mat-label>{{ 'supplier.state' | translate }}</mat-label>
                        <mat-select [(ngModel)]="supplier.supplierstate" (ngModelChange)="onSupplierChange($event)">
                          <mat-option *ngFor="let state of supplierStatesArray" [value]="state.value">
                            {{ 'supplier.' + state.viewValue | translate }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                </div>
                <div class="row">
                    <mat-form-field>
                        <mat-label>{{ 'supplier.ingeststate' | translate }}</mat-label>
                        <mat-select [(ngModel)]="supplier.ingestsupplierstate" (ngModelChange)="onSupplierChange($event)">
                          <mat-option *ngFor="let state of ingestSupplierStatesArray" [value]="state.value">
                            {{ 'supplier.' + state.viewValue | translate }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                </div>
                <div class="row">
                    <mat-form-field class="full-width">
                        <mat-label>{{ 'supplier.url' | translate }}</mat-label>
                        <input matInput [(ngModel)]="supplier.url">
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-form-field class="full-width">
                        <mat-label>{{ 'supplier.logo' | translate }}</mat-label>
                        <input matInput [(ngModel)]="supplier.logo">
                    </mat-form-field>
                </div>
                <div class="row">
                   <img src="{{ supplier.logo }}">
                </div>
            </div>
        </div>
    </mat-tab>
</mat-tab-group>
