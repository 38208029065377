<mat-nav-list>
  <mat-list-item>
    <span matListItemTitle>{{ 'sort.price' | translate }}</span>
    <mat-checkbox [color]="'primary'" [checked]="this.dataAccessServices.filters.upperPrice">
      {{ 'sort.upperprice' | translate }}
    </mat-checkbox>
  </mat-list-item>
  <mat-list-item>
    <span matListItemTitle>{{ 'sort.name' | translate }}</span>
    <mat-checkbox [color]="'primary'" [checked]="this.dataAccessServices.filters.upperName">
      {{ 'sort.uppername' | translate }}
    </mat-checkbox>
  </mat-list-item>
</mat-nav-list>
