import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from 'src/environments/environment';

import { RealmTokenServices } from '../commons/realm.token.services';

import { Supplier } from './../../models/supplier.model';

@Injectable({
  providedIn: 'root'
})
export class SuppliersServices {

  constructor(
    private httpClient: HttpClient,
    private realmTokenServices: RealmTokenServices
  ) { }

    public async getSuppliers(): Promise<Array<Supplier>> {
      console.log('SuppliersServices.getSuppliers: Start to get suppliers');
      
      return new Promise<Array<Supplier>>(async (resolve, reject) => {

        let token = null;
        await this.realmTokenServices.getToken().then(data => {
          console.log('SuppliersServices.getSuppliers : Token', data);
          token = data;
        });

        const findUrl = `${environment.mongoapi.apiFindUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        
        await this.httpClient.post<Supplier[]>(findUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `${environment.database.prefix}suppliers` }, httpOptions).toPromise().then((data: any) => {
          console.log('SuppliersServices.getSuppliers : Suppliers', data);
          resolve(data.documents);
        });
      });
    }

    public async updateSupplier(supplier: Supplier): Promise<Supplier> {
      console.log('SuppliersServices.updateSupplier: Update an user', supplier);
      return new Promise<Supplier>(async (resolve, reject) => {
  
        let token = null;
        await this.realmTokenServices.getToken().then(data => {
          console.log('SuppliersServices.updateSupplier : Token', data);
          token = data;
        });
  
        const updateUrl = `${environment.mongoapi.apiUpdateOneUrl}?apiKey=${environment.mongoapi.applicationKey}&Content-Type=application/ejson&Accept=application/json`;
  
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        
        await this.httpClient.post<Supplier>(updateUrl, { dataSource: environment.mongoapi.dataSource, database: environment.mongoapi.database, collection: `${environment.database.prefix}suppliers`, filter: { '_id': { $eq: { $oid: supplier._id } } }, update: { $set: { 'supplierstate': supplier.supplierstate, 'ingestsupplierstate': supplier.ingestsupplierstate } } }, httpOptions).toPromise().then((data: any) => {
          console.log('SuppliersServices.updateSupplier : Supplier updated', data);
          if(data.matchedCount > 0) {
            resolve(supplier);
          } else {
            reject(data);
          }
        });
  
      });
    }  

}
