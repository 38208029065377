import { EventEmitter, Injectable, OnDestroy, Output } from '@angular/core';
import { Subject } from 'rxjs';

import { List } from 'linqts';

import { SuppliersServices } from '../suppliers/suppliers.services';

import { User } from 'src/app/models/user';
import { Item } from 'src/app/models/item';
import { Filters } from 'src/app/models/filters';
import { Supplier } from 'src/app/models/supplier.model';
import { SupplierState } from 'src/app/models/supplier.state.enum';
import { IngestSupplierState } from 'src/app/models/ingest.supplier.state.enum';

@Injectable({
  providedIn: 'root'
})
export class DataAccessServices implements OnDestroy {

  @Output() cacherefreshed: EventEmitter<boolean> = new EventEmitter();
  public iscacherefreshed: boolean = false;

  public currentUser: User = null;

  public allSuppliers: Array<Supplier> = new Array<Supplier>();
  public suppliers: Array<Supplier> = new Array<Supplier>();

  public filters: Filters = new Filters();
  
  public allItems: Array<Item> = new Array<Item>();
  public items: Array<Item> = new Array<Item>();
  public itemsPageIndex: number = 1;
  public itemsPageSize: number = 20;
  public itemsTotal: number = 0; 

  private pageEventSource = new Subject<void>();
  pageEvent$ = this.pageEventSource.asObservable();

  

  constructor(
    private suppliersServices: SuppliersServices
    ) { }

  public ngOnDestroy(): void {
  }

  public triggerPageEvent() {
    this.pageEventSource.next();
  }

  public async initialize(): Promise<boolean> {
    console.log('DataAccessServices.initialize: Start to initialize data');

    return new Promise<boolean>(async (resolve, reject) => {

      if (this.currentUser === null || this.currentUser === undefined) {
        reject(false);
      } else {

        await this.suppliersServices.getSuppliers().then((data: Array<Supplier>) => {
          console.log('DataAccessServices.initialize: Get suppliers', data);
          this.allSuppliers = data;
          const filteredSuppliers = new List(data).Where(s => s.supplierstate === SupplierState.DemandApproved && s.ingestsupplierstate === IngestSupplierState.Done).ToArray();
          filteredSuppliers.forEach(supplier => supplier.totalItems = 0);
          this.suppliers = filteredSuppliers;
        });
        
        this.iscacherefreshed = true;
        this.cacherefreshed.emit(true);

        resolve(true);
      }
      
      resolve(true);
    });
  }
}
