<mat-card class="item-card">
    <mat-card-header>
      <mat-card-title>
        {{ (item.name.length>25)? (item.name | slice:0:30)+'...':(item.name) }}
      </mat-card-title>
      <mat-card-subtitle>
        {{ (item.designlinename !== null && item.designlinename !== undefined && item.designlinename.length>50)? (item.designlinename | slice:0:50)+'...':(item.designlinename) }}
      </mat-card-subtitle>
    </mat-card-header>
    <img *ngIf="item.thumbnail !== null && item.thumbnail !== undefined" mat-card-image src="{{ item.thumbnail }}" alt="Item picture">
    <img *ngIf="item.thumbnail === null || item.thumbnail === undefined" mat-card-image src="./../../../assets/images/empty-picture.jpg" alt="No picture">
    <mat-card-content>
      <p>
        {{ (item.description.length>30)? (item.description | slice:0:25)+'...':(item.description) }}
      </p>
    </mat-card-content>
    <mat-card-actions>
      <div class="row full-width">
        <div class="col-6">
          <button mat-fab extended color="primary" [routerLink]="['/details', item._id]">
            {{ 'item.details' | translate }}
          </button>
        </div>
        <div class="col-6">
          <img *ngIf="item.supplier.logo !== null && item.supplier.logo !== undefined" mat-card-image src="{{ item.supplier.logo }}" alt="Supplier logo" [ngStyle]="{ 'max-width': settingsServices.isCellPhone ? '30vw' : '5vw' }">
        </div>
      </div>
    </mat-card-actions>
  </mat-card>
  