<div class="row">
    <form class="col-9">
        <mat-form-field class="w-100">
            <input matInput name="searchText" type="search" placeholder="Ex. Desk" [(ngModel)]="searchText" (keyup.enter)="searchItems()">
        </mat-form-field>
    </form>
    <span class="col-3 d-flex justify-content-end">
        <button mat-fab extended color="primary" (click)="searchItems()">
            <mat-icon [ngStyle]="{ 'margin-right': settingsServices.isCellPhone ? '-10px' : '12px' }">search</mat-icon>
            <span *ngIf="settingsServices.isCellPhone === false">{{ 'search.search' | translate }}</span>
        </button>
    </span>
</div>


  