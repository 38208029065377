import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnInit {

  @Output() public avatarLoaded = new EventEmitter<string>();

  @Input() public isEditable: boolean = false;
  @Input() public name: string;
  @Input() public imageSource: string;
  @Input() public size: string = '100px';

  public avatar: any;
  public showInitials = false;
  public initials: string;
  public circleColor: string;
  public fontsize: string;

  private colors = [
    '#e2e2e2'
  ];

  @Input() progress = 0;

  constructor(private domSanitizer: DomSanitizer) { }

  ngOnInit() {

    if (this.size !== null && this.size !== undefined) {
      this.fontsize = parseInt(this.size.replace('px', '')) / 2 + 'px';
    }

    if (this.imageSource === null || this.imageSource === undefined || this.imageSource === '\\x') {
      console.log('AvatarComponent.ngOnInit : Initialize component with initiales name ' + this.name);
      this.showInitials = true;
      this.createInititals();

      const randomIndex = Math.floor(Math.random() * Math.floor(this.colors.length));
      this.circleColor = this.colors[randomIndex];
    } else {
      console.log('AvatarComponent.ngOnInit : Initialize component with picture ' + this.imageSource);
      this.avatar = this.domSanitizer.bypassSecurityTrustResourceUrl(this.imageSource);
    }
  }

  public onFileDropped(files: any) {
    let file = files[0];
    console.log('AvatarComponent.onFileDropped : File dropped ', file);
    const reader = new FileReader();
    reader.onload = (event) => {
      this.avatar = reader.result;
      this.avatarLoaded.emit(reader.result as string);
    }
    reader.readAsDataURL(file);
  }

  private createInititals(): void {
    let initials = "";

    for (let i = 0; i < this.name.length; i++) {
      if (this.name.charAt(i) === ' ') {
        continue;
      }

      if (this.name.charAt(i) === this.name.charAt(i).toUpperCase()) {
        initials += this.name.charAt(i);

        if (initials.length == 2) {
          break;
        }
      }
    }

    console.log('AvatarComponent.createInititals : The name ' + this.name + ' has initials ' + initials);

    this.initials = initials;
  }
}
