<div class="mt-3 container-fluid">
    <div class="row mt-3">
        <app-search (searchResultEvent)="setSearchResult($event)" (isSearchingEvent)="toggleIsSearching($event)"></app-search>
    </div>
    <div class="row mt-3">
        <app-filters (filtersChanged)="toggleFilters($event)"></app-filters>
    </div>
</div>

<div *ngIf="isSearching" class="row mt-5">
    <div class="col-md-12 d-flex justify-content-center">
        <app-loader></app-loader>
    </div>
</div>
<div *ngIf="isSearching === false" class="row mt-3">
    <div *ngFor="let item of this.dataAccessServices.items" class="col-md-2">
        <app-item [item]="item"></app-item>
    </div>
    <mat-paginator *ngIf="this.dataAccessServices.items && this.dataAccessServices.items.length > 0" 
        [pageSizeOptions]="[10, 20, 50, 100, 200, 500]"
        [pageSize]="dataAccessServices.itemsPageSize"
        [pageIndex]="dataAccessServices.itemsPageIndex"
        [length]="dataAccessServices.itemsTotal"
        (page)="handlePageEvent($event)"
        showFirstLastButtons
        aria-label="Select page">
    </mat-paginator>
</div>
