import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { List } from 'linqts';

import { SettingsServices } from 'src/app/services/commons/settings.services';
import { DataAccessServices } from 'src/app/services/commons/data.access.services';
import { ItemsServices } from 'src/app/services/items/items.services';

import { Item } from 'src/app/models/item';
import { ItemPicture } from 'src/app/models/item.picture';
import { GeocodingServices } from 'src/app/services/commons/geocoding.services';

declare var google: any;

@Component({
  selector: 'app-item-details',
  templateUrl: './item-details.component.html',
  styleUrls: ['./item-details.component.scss']
})
export class ItemDetailsComponent {

  public selectedItem: Item = null;

  constructor(
    private router: Router, 
    public settingsServices: SettingsServices,
    private activatedRoute: ActivatedRoute,
    private geocodingServices: GeocodingServices,
    private dataAccessServices: DataAccessServices,
    private itemsServices: ItemsServices) {

    this.activatedRoute.params.subscribe(params => {
      console.log('ItemDetailsComponent.constructor : Retrieved params', params);
      const itemId = params['itemid'];
      if(this.dataAccessServices.items === null || this.dataAccessServices.items === undefined || this.dataAccessServices.items.length === 0) {
        this.router.navigate(['/search']);
      }
      this.selectedItem = new List(this.dataAccessServices.items).First(i => i._id == itemId);
      if(this.selectedItem === null || this.selectedItem === undefined) {
        this.router.navigate(['/search']);
      }
    });
  }

  ngOnInit(): void {
    this.selectedItem.score++;
    this.updateItem();
    this.getItemPictures();
    this.initMap();
  }

  public async getItemPictures(): Promise<void> {
    await this.itemsServices.getItemPictures(this.selectedItem).then((data: Array<ItemPicture>) => {
      console.log('ItemDetailsComponent.getItemPictures : Retrieved item pictures', data);
      this.selectedItem.pictures = data;
    });
  }

  public openSupplierLink(): void {
    window.open(this.selectedItem.url, '_blank');
  }

  private async updateItem(): Promise<void> {
    await this.itemsServices.updateItem(this.selectedItem).then((data: Item) => {
      console.log('ItemDetailsComponent.updateItem : Item updated', data);
    });
  }

  private async initMap(): Promise<void> {

    const address = `${this.dataAccessServices.currentUser.city} ${this.dataAccessServices.currentUser.country}`;
    let latitude = 0;
    let longitude = 0;

    await this.geocodingServices.getCoordinatesFromAddress(address).then((data: any) => {
        if (data.status === 'OK' && data.results.length > 0) {
          const location = data.results[0].geometry.location;
          latitude = location.lat;
          longitude = location.lng;
          console.log(`ItemDetailsComponent.initMap : Latitude: ${latitude}, Longitude: ${longitude}`);
        } else {
          console.error('ItemDetailsComponent.initMap : Adresse non trouvée');
        }
      },
      (error) => {
        console.error('ItemDetailsComponent.initMap : Erreur lors de la récupération des coordonnées.');
      }
    );

    console.log(`ItemDetailsComponent.initMap : Initialize with Latitude: ${latitude}, Longitude: ${longitude}`);

    const mapOptions = {
      center: { lat: latitude, lng: longitude },
      zoom: 10, 
    };
  
    const map = new google.maps.Map(document.getElementById('map'), mapOptions);
  
    // Ajoutez un marqueur à l'adresse spécifiée
    new google.maps.Marker({
      position: { lat: latitude, lng: longitude },
      map: map,
      title: 'Me',
    });

    const storeAddresses = `${this.selectedItem.supplier.name} ${this.dataAccessServices.currentUser.city}`;
    await this.geocodingServices.getCoordinatesFromStoreName(storeAddresses).then(data => {
      if (data.status === 'OK' && data.results.length > 0) {
        for(let i = 0; i < data.results.length; i++) {
          const element = data.results[i];
          console.log(`ItemDetailsComponent.initMap : Compute the element Latitude: ${element.geometry.location.lat}, Longitude: ${element.geometry.location.lng}`, element);
          new google.maps.Marker({
            position: { lat: element.geometry.location.lat, lng: element.geometry.location.lng },
            map: map,
            title: this.selectedItem.supplier.name,
          });
          console.log(`ItemDetailsComponent.initMap : ${this.selectedItem.supplier.name} Latitude: ${latitude}, Longitude: ${longitude}`);
        }
      } else {
        console.error(`ItemDetailsComponent.initMap : ${this.selectedItem.supplier.name} Adresse non trouvée`);
      }

    });
  }
}
