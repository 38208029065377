<div class="row form-group" *ngIf="settingsServices.isCellPhone === true">
  <div class="login-logo-cellphone d-flex justify-content-center" (click)="redirectToGlobalVesta()">
    <img src="assets/images/logo_gv.png" alt="logo" class="login-logo-image-cellphone" />
  </div>
</div>

<div class="row form-group">
    <div class="col-md-5" *ngIf="settingsServices.isCellPhone === false" (click)="redirectToGlobalVesta()">
      <div class="login-logo">
        <img src="assets/images/logo_gv.png" alt="logo" class="login-logo-image" />
      </div>
    </div>
    <div class="col-md-7">
      <form class="login-form">
  
        <div class="row form-group">
          <mat-form-field>
            <mat-label>{{ 'login.email' | translate }}</mat-label>
            <input matInput name="email" placeholder="your@email.com" [(ngModel)]="email">
          </mat-form-field>
        </div>
  
        <div class="row form-group">
          <mat-form-field>
            <input matInput name="password" [type]="hidePassword ? 'password' : 'text'" [(ngModel)]="password">
            <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword">
              <mat-icon>{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
            </button>
          </mat-form-field>
        </div>
  
        <div class="row form-group d-flex justify-content-center">
          <mat-spinner diameter="50" *ngIf="isAuthenticating"></mat-spinner>
          <button mat-raised-button color="primary" class="btn-block" (click)="authenticate()" *ngIf="!isAuthenticating">{{ 'login.connection' | translate }}</button>
        </div>

        <div class="row form-group mt-5">
          <button mat-fab extended color="primary" (click)="redirectToGlobalVestaCommercial()">
            {{ 'login.noaccount' | translate }}
          </button>
        </div>

        <div class="row form-group mt-5">
          <mat-label><small>{{ 'login.productdescription' | translate }}</small></mat-label>
        </div>
  
      </form>
    </div>
  </div>
  
  